import {
  ButtonV3,
  GridItem,
  InverseHeader,
  LayoutGrid,
  TypographyV3,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import Motion from '@/app/components/Motion';
import { WHY_OURA_PATHNAME } from '@/app/consts/pathnames';

import HomeSlider from './HomeSlider';

const HomeMembership = (): JSX.Element => {
  const t = useTranslations();

  const handleClick = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.LinkClicked, {
      cta: 'why oura',
      location: 'home membership',
      path: WHY_OURA_PATHNAME,
    });
  };

  return (
    <section className="overflow-hidden pb-16" data-cy="home-membership">
      <LayoutGrid>
        <GridItem
          className="flex flex-col items-center py-16 lg:py-32"
          colEnd={{ sm: 'main', md: 21, lg: 21, xl: 20 }}
          colStart={{ sm: 'main', md: 5, lg: 5, xl: 6 }}
        >
          <Motion
            initial={{ filter: 'blur(10px)' }}
            whileInView={{ filter: 'blur(0px)', transition: { duration: 1 } }}
            viewport={{ once: true }}
          >
            <TypographyV3 variant="h1" align="center" height="tight">
              {t.rich('membership_gives_body_voice')}
            </TypographyV3>
          </Motion>
          <ButtonV3
            href="/why-oura"
            variant="secondary-dark"
            className="relative mt-8 lg:mt-12"
            onClick={handleClick}
          >
            {t('why_oura_link')}
          </ButtonV3>
        </GridItem>
        <GridItem
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'main' }}
          className="relative"
        >
          <InverseHeader enter>
            <HomeSlider />
          </InverseHeader>
        </GridItem>
      </LayoutGrid>
    </section>
  );
};

export default HomeMembership;
