import { QuoteIcon, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import MobileFeature from './MobileFeature';

const WindDown = (): JSX.Element => {
  const t = useTranslations();
  return (
    <MobileFeature
      title="features_tab_4_label"
      image={{
        alt: 'tab_item_wind_down_img_alt',
        src: 'blue-sky/home/winding-down',
      }}
    >
      <div className="relative hidden lg:block" data-cy="wind-down">
        <Image
          alt={t('tab_item_wind_down_img_alt')}
          src="blue-sky/home/winding-down"
          height={547}
          width={400}
          className="rounded-lg object-cover"
        />
      </div>
      <div
        className={cx(
          'absolute bottom-16 left-1/2 w-[80vw] max-w-[300px] -translate-x-1/2 flex-col gap-y-6',
          'lg:relative lg:bottom-auto lg:left-0 lg:mt-14 lg:flex lg:w-auto lg:max-w-none lg:translate-x-0',
        )}
      >
        <div className="hidden grid-cols-1 grid-rows-1 lg:grid">
          <Image
            alt=""
            className="col-start-1 col-end-2 row-start-1 row-end-2 ml-[15%] rounded-lg"
            src="blue-sky/home/winding-down-mask-1"
            width={328}
            height={81}
          />
          <Image
            alt={t('bedtime_approach_notification_image_alt')}
            className="col-start-1 col-end-2 row-start-1 row-end-2 ml-[15%] rounded-lg"
            src={t('bedtime_approach_notification')}
            format="png"
            width={328}
            height={81}
          />
        </div>
        <div className="block gap-x-6 lg:flex">
          <div>
            <div className="hidden grid-cols-1 grid-rows-1 lg:grid">
              <Image
                alt=""
                className="col-start-1 col-end-2 row-start-1 row-end-2 w-auto rounded-lg"
                src="blue-sky/home/winding-down-mask-2"
                width={300}
                height={293}
              />
              <Image
                alt={t('bedtime_approach_app_image_alt')}
                className="col-start-1 col-end-2 row-start-1 row-end-2 w-auto self-center rounded-lg"
                src={t('bedtime_approach_app_ui')}
                format="png"
                width={300}
                height={293}
              />
            </div>
            <Motion
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, type: 'tween', delay: 0.5 }}
              className="grid grid-cols-1 grid-rows-1 lg:hidden"
            >
              <Image
                alt=""
                className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
                src="blue-sky/home/wind-down-mask-mobile"
                width={300}
                height={277}
              />
              <Image
                alt={t('bedtime_approach_app_image_alt')}
                className="col-start-1 col-end-2 row-start-1 row-end-2 w-auto self-center rounded-lg"
                src={t('bedtime_approach_app_ui')}
                format="png"
                width={300}
                height={277}
              />
            </Motion>
          </div>
          <div className="hidden rounded-lg border border-gray-300 px-10 py-12 lg:block">
            <QuoteIcon className="text-olive-700" />
            <TypographyV3
              className="mt-4 text-3xl"
              font="serif"
              variant="unset"
            >
              {t.rich('features_tab_4_quote')}
            </TypographyV3>
            <TypographyV3
              className="mt-4 text-olive-700"
              variant="body-small"
              color="unset"
            >
              Rhonda C., {t('oura_member')}
            </TypographyV3>
          </div>
        </div>
      </div>
    </MobileFeature>
  );
};

export default WindDown;
