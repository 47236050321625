import { useQuery } from '@tanstack/react-query';
import request, { gql, type Variables } from 'graphql-request';

import { getGraphQlEndpoint } from '@/api/backendAPI';
import { RingStyleEnum } from '@/app/enums/RingStyleEnum';
import useCurrency from '@/app/hooks/useCurrency';
import type { RingStyle } from '@/app/types/ring';
import { getCartIdCookie } from '@/utils/cartUtils';

import type {
  ProductPricing,
  ProductPricing_content,
  ProductPricingVariables,
} from './types/ProductPricing';
import type {
  Products_content_listProducts_discountedPrice_discountedPrice,
  ProductsVariables,
} from './types/Products';

export interface RingPricingData {
  lowestPrice: number;
}

const query = gql`
  query ProductPricing(
    $cartId: String
    $region: String
    $currency: String
    $flags: [String]
    $productType: String
  ) {
    content(region: $region, currency: $currency, enabledFeatureFlags: $flags) {
      listProducts(productType: $productType) {
        comparePrice {
          amount
          currencyCode
        }
        discountedPrice(cartId: $cartId) {
          originalPrice {
            amount
            currencyCode
          }
          discountedPrice {
            amount
            currencyCode
          }
        }
        oldPrice {
          amount
          currencyCode
        }
        price {
          amount
          currencyCode
        }
        product_type
        productKey
      }
    }
  }
`;

export const DEFAULT_VARS: ProductsVariables = {
  cartId: null,
  region: null,
  currency: null,
  flags: [],
  productType: 'All',
};

export function withQueryKey(vars: ProductsVariables) {
  const cartId = getCartIdCookie();

  // Enforce that we always have consitent cache keys that match the graphql query
  const queryVars: ProductPricingVariables = {
    ...DEFAULT_VARS,
    ...vars,
    cartId,
  };

  return {
    queryKey: ['useProductPricingData', { vars: queryVars }],
    queryFn: async (): Promise<ProductPricing_content['listProducts']> => {
      const url = getGraphQlEndpoint()!;
      const data: ProductPricing = await request(
        url,
        query,
        queryVars as Variables,
      );
      return data.content?.listProducts ?? [];
    },
  };
}

const getRingStyleFromProductKey = (productKey: string): RingStyle => {
  if (productKey.includes('Heritage')) return RingStyleEnum.Heritage;
  if (productKey.includes('Horizon')) return RingStyleEnum.Horizon;
  return RingStyleEnum.OuraRing4;
};

export function useRingsLowestPricing() {
  const currency = useCurrency();

  return useQuery({
    ...withQueryKey({
      currency: currency.currencyCode,
      productType: 'Ring',
    }),
    select: (
      data: ProductPricing_content['listProducts'],
    ): Record<RingStyle, RingPricingData> => {
      const output = new Map<RingStyle, RingPricingData>();
      data
        ?.filter((product) => product !== null)
        .filter(({ productKey }) => !productKey.includes('Test'))
        .forEach(({ discountedPrice, productKey }) => {
          const ringStyle: RingStyle = getRingStyleFromProductKey(productKey);
          const lowestPrice: Products_content_listProducts_discountedPrice_discountedPrice =
            discountedPrice.discountedPrice!.amount! <
            discountedPrice.originalPrice!.amount!
              ? discountedPrice.discountedPrice!
              : discountedPrice.originalPrice!;
          if (!output.get(ringStyle)) {
            output.set(ringStyle, {
              lowestPrice: lowestPrice.amount!,
            });
          } else {
            const currentLowestPrice: number =
              output.get(ringStyle)!.lowestPrice;
            const newLowestPrice: number =
              currentLowestPrice < lowestPrice.amount!
                ? currentLowestPrice
                : lowestPrice.amount!;
            output.set(ringStyle, {
              lowestPrice: newLowestPrice,
            });
          }
        });
      return Object.fromEntries(output.entries()) as Record<
        RingStyle,
        RingPricingData
      >;
    },
  });
}
