import { type CdnOptions } from './types/CdnOptions';

export const VIDEO_FORMATS = ['hls', 'mp4'] as const;

export const VIDEO_RESOLUTIONS = ['high', 'medium', 'low'] as const;

export const DEFAULT_CDN_OPTIONS: CdnOptions = {
  fm: 'mp4',
  res: 'high',
};
