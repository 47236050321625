import RingAssessmentModalContent from './RingAssessmentModalContent';
import RingAssessmentModalTrigger from './RingAssessmentModalTrigger';

const RingAssessmentModal = (): JSX.Element => {
  return (
    <RingAssessmentModalTrigger autoShowDelay={7000}>
      {(overlayState) => <RingAssessmentModalContent {...overlayState} />}
    </RingAssessmentModalTrigger>
  );
};

export default RingAssessmentModal;
