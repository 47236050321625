import { type CdnOptions } from '../types/CdnOptions';
import { getImgixSrc } from '../utils';

const imgixLoader = (src: string, cdnOptions: CdnOptions = {}): string => {
  return getImgixSrc(src, {
    fm: 'mp4',
    res: 'high',
    ...cdnOptions,
  });
};

export default imgixLoader;
