import {
  SwipeableProgressBar,
  SwipeableProvider,
  SwipeableSlider,
} from '@jouzen/ecom-components';
import { useCallback, useRef, useState } from 'react';

import Motion from '@/app/components/Motion';
import SwipeableNavButtons from '@/app/components/SwipeableNavButtons';

import { SLIDER_CONTENT_OR4 } from './_data/membershipSliderContent';
import ModalTrigger from './_modal/ModalTrigger';
import HomeOverlayContent from './HomeOverlayContent';
import HomeSliderItem from './HomeSliderItem';

const HomeSlider = (): JSX.Element => {
  const [expandAtId, setExpandAtId] = useState<number>(-1);
  const [openOverlayAtId, setOpenOverlayAtId] = useState<number>(-1);
  const containerRef = useRef<HTMLDivElement>(null);

  // expand the card on desktop
  const handleExpand = useCallback(
    (id: number) => {
      if (expandAtId === id) {
        setExpandAtId(-1);
      } else {
        setExpandAtId(id);
      }
    },
    [expandAtId, setExpandAtId],
  );

  // open the overlay on mobile
  const handleOverlayOpen = useCallback(
    (id: number) => {
      if (openOverlayAtId === id) {
        setOpenOverlayAtId(-1);
      } else {
        setOpenOverlayAtId(id);
      }
    },
    [openOverlayAtId, setOpenOverlayAtId],
  );

  // change slide views in the overlay
  const handleChange = useCallback(
    (id: number) => {
      setOpenOverlayAtId(id);
    },
    [setOpenOverlayAtId],
  );

  // close the overlay
  const handleClose = useCallback(() => {
    setOpenOverlayAtId(-1);
  }, [setOpenOverlayAtId]);

  // close the expanded card
  const handleCloseCard = useCallback(() => {
    setExpandAtId(-1);
  }, [setExpandAtId]);

  return (
    <Motion
      data-cy="home-slider"
      ref={containerRef}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, type: 'tween', delay: 0.25 }}
    >
      <SwipeableProvider expandable={expandAtId !== -1}>
        <div className="flex flex-col-reverse">
          <div className="relative mr-6 max-w-[1312px]">
            <SwipeableProgressBar />
            <SwipeableNavButtons onClick={handleCloseCard} />
          </div>
          <SwipeableSlider className="pr-6">
            {SLIDER_CONTENT_OR4.map((slide) => {
              return (
                <HomeSliderItem
                  key={slide.id}
                  expandAtId={expandAtId}
                  slide={slide}
                  onExpand={handleExpand}
                  onOverlayOpen={handleOverlayOpen}
                />
              );
            })}
          </SwipeableSlider>
        </div>
        <ModalTrigger state={{ isOpen: openOverlayAtId !== -1 }}>
          <HomeOverlayContent
            openOverlayAtId={openOverlayAtId}
            onChange={handleChange}
            onClose={handleClose}
          />
        </ModalTrigger>
      </SwipeableProvider>
    </Motion>
  );
};

export default HomeSlider;
