import { cx } from 'class-variance-authority';
import type { MotionProps as FramerMotionProps } from 'framer-motion';
// eslint-disable-next-line no-restricted-imports -- Need to import m here
import { domAnimation, LazyMotion, m } from 'framer-motion';
import type { SVGAttributes } from 'react';
import { forwardRef } from 'react';

export interface MotionProps extends FramerMotionProps {
  readonly className?: string | undefined;
  readonly cx?: SVGAttributes<SVGCircleElement>['cx'];
  readonly cy?: SVGAttributes<SVGCircleElement>['cx'];
  readonly el?: keyof typeof m;
  readonly r?: SVGAttributes<SVGCircleElement>['r'];
  readonly strokeDasharray?: SVGAttributes<SVGCircleElement>['strokeDasharray'];
  readonly strokeDashoffset?: SVGAttributes<SVGCircleElement>['strokeDashoffset'];
  readonly strokeWidth?: SVGAttributes<SVGCircleElement>['strokeWidth'];
  readonly suppressHydrationWarning?: boolean;
}

const Motion = forwardRef<HTMLElement | SVGElement, MotionProps>(
  function Motion(
    { children, className, el = 'div', ...props }: MotionProps,
    ref,
  ): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- Ref type conflicts with forwardRef typing.
    const Component = m[el] as any;
    return (
      <LazyMotion features={domAnimation}>
        <Component
          className={cx('motionComponent', className)}
          ref={ref}
          {...props}
        >
          {children}
        </Component>
      </LazyMotion>
    );
  },
);

export default Motion;
