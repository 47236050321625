import type { ReactNode } from 'react';
import { useRef } from 'react';
import {
  type AriaModalOverlayProps,
  Overlay,
  useModalOverlay,
} from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

interface ModalProps extends AriaModalOverlayProps {
  readonly children: ReactNode;
  readonly state: Partial<OverlayTriggerState>;
}

const Modal = ({ state, children, ...props }: ModalProps) => {
  const ref = useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(
    props,
    state as OverlayTriggerState,
    ref,
  );

  return (
    <Overlay>
      <div className="fixed inset-0 z-100" {...underlayProps}>
        <div {...modalProps} ref={ref}>
          {children}
        </div>
      </div>
    </Overlay>
  );
};

export default Modal;
