import { type PropsWithChildren, useRef } from 'react';
import type { AriaModalOverlayProps } from 'react-aria';
import { Overlay, useModalOverlay } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import Motion from '@/app/components/Motion';

export interface RingAssessmentModalOverlayProps
  extends AriaModalOverlayProps,
    PropsWithChildren {
  readonly state: OverlayTriggerState;
}

const RingAssessmentModalOverlay = ({
  children,
  state,
  ...props
}: RingAssessmentModalOverlayProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  return (
    <Overlay>
      <Motion
        animate={{ opacity: 1 }}
        className="fixed inset-0 z-200"
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.333, type: 'tween' }}
      >
        <div
          {...underlayProps}
          className="size-full bg-black/50"
          data-cy="ring-assessment-modal-underlay"
        >
          <div
            {...modalProps}
            className="flex size-full items-center justify-center p-4"
            ref={ref}
          >
            {children}
          </div>
        </div>
      </Motion>
    </Overlay>
  );
};

export default RingAssessmentModalOverlay;
