import type { ReactNode } from 'react';
import type { OverlayTriggerState } from 'react-stately';

import Modal from './Modal';

interface ModalTriggerProps {
  readonly children: ReactNode;
  readonly state: Partial<OverlayTriggerState>;
}

const ModalTrigger = ({
  children,
  state,
  ...props
}: ModalTriggerProps): JSX.Element => {
  return (
    <>
      {state.isOpen && (
        <Modal {...props} state={state}>
          {children}
        </Modal>
      )}
    </>
  );
};

export default ModalTrigger;
