import { Tabs } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';

import HostingParty from './HostingParty';
import StartDay from './StartDay';
import TakeAWalk from './TakeAWalk';
import UnderWeather from './UnderWeather';
import WindDown from './WindDown';

interface HomeFeaturesTabsProps {
  readonly onTabChange: (index: number) => void;
}

export const HomeFeaturesTabs = ({
  onTabChange,
}: HomeFeaturesTabsProps): JSX.Element => {
  const t = useTranslations();
  const tabs = [
    {
      label: t('features_tab_1_label'),
    },
    {
      label: t('features_tab_2_label'),
    },
    {
      label: t('features_tab_3_label'),
    },
    {
      label: t('features_tab_4_label'),
    },
    {
      label: t('features_tab_5_label'),
    },
  ];

  const tabPanels = [
    {
      content: <StartDay />,
    },
    {
      content: <TakeAWalk />,
    },
    {
      content: <UnderWeather />,
    },
    {
      content: <WindDown />,
    },
    {
      content: <HostingParty />,
    },
  ];
  return <Tabs tabPanels={tabPanels} tabs={tabs} onChange={onTabChange} />;
};
