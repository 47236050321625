import ImgixClient from '@imgix/js-core';

import { getLegacyEnvVariable } from '@/app/components/LegacyEnvVariables/util';

import { DEFAULT_CDN_OPTIONS } from '../const';
import { type CdnOptions } from '../types/CdnOptions';
import getSrcUrl from './getSrcUrl';

const getImgixSrc = (src: string, cdnOptions: CdnOptions = {}): string => {
  const imgixClient = new ImgixClient({
    domain: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN')!,
    secureURLToken: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN')!,
  });

  const srcUrl = getSrcUrl(src);

  return imgixClient.buildURL(srcUrl, {
    ...DEFAULT_CDN_OPTIONS,
    ...cdnOptions,
  });
};

export default getImgixSrc;
