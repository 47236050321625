import { useTranslations } from 'next-intl';
import type { MouseEvent } from 'react';

import { EventType } from '@/analytics/types';
import ArticleCardsModule from '@/app/components/ArticleCardsModule';

import { ARTICLE_CARDS, ARTICLE_IMAGE } from './_data/homePress';

const HomePress = (): JSX.Element => {
  const t = useTranslations();

  const handleLinkClick = async (
    event: MouseEvent<HTMLAnchorElement>,
  ): Promise<void> => {
    const { href } = event.currentTarget;
    const siteName = event.currentTarget.dataset['siteName'];
    await window.ouraAnalytics.track(EventType.LinkClicked, {
      cta: siteName,
      location: 'body',
      path: href,
    });
  };

  return (
    <ArticleCardsModule
      articleCards={ARTICLE_CARDS}
      articleImage={ARTICLE_IMAGE}
      eyebrowText="press_eyebrow"
      eyebrowTextColor="light"
      mobileSliderAriaLabels={[
        t('press_section_slide_label_cnbc'),
        t('press_section_slide_label_tech_crunch'),
        t('press_section_slide_label_verge'),
      ]}
      onClick={handleLinkClick}
      swipeableDotsProps={{
        activeColor: 'sandstone-200',
        color: 'sandstone-500',
      }}
      wrapperClass="bg-gray-600"
    />
  );
};

export default HomePress;
