'use client';

import {
  type Gradient,
  InverseHeader,
  RadialGradientBG,
} from '@jouzen/ecom-components';
import { useState } from 'react';

import useScrollTracking from '../hooks/useScrollTracking';
import HomeFeatures from './_components/HomeFeatures';
import HomeHeroGate from './_components/HomeHero/HomeHeroGate';
import HomeMembership from './_components/HomeMembership';
import HomePress from './_components/HomePress';
import HomeRingModules from './_components/HomeRingsModules';
import RingAssessmentModal from './ring-assessment/_components/RingAssessmentModal';

const sectionGradients: Gradient[] = [
  {
    shape: 'circle',
    hex: '#EADCCF',
    id: 'cream-1',
    position: [20, 20],
    opacity: 1,
    size: 20,
  },
  {
    shape: 'circle',
    hex: '#B6CFDD',
    id: 'blue-1',
    position: [0, 40],
    opacity: 0.5,
    size: 20,
  },
  {
    shape: 'circle',
    hex: '#F3EBE1',
    id: 'cream-2',
    position: [20, 55],
    opacity: 1,
    size: 20,
  },
  {
    shape: 'circle',
    hex: '#B5E4FE',
    id: 'blue-2',
    position: [99, 27],
    opacity: 0.5,
    size: 20,
  },
];

const coolGradients: Gradient[] = [
  {
    shape: 'ellipse',
    hex: '#D5C3AB',
    id: 'neutral',
    position: [45, 55],
    opacity: 0.4,
    size: 60,
  },
  {
    shape: 'circle',
    hex: '#74A9B8',
    id: 'blue',
    position: [50, 85],
    opacity: 0.3,
    size: 40,
  },
];

const warmGradients: Gradient[] = [
  {
    shape: 'ellipse',
    hex: '#D5C3AB',
    id: 'neutral',
    position: [45, 55],
    opacity: 0.4,
    size: 60,
  },
  {
    shape: 'circle',
    hex: '#FFB648',
    id: 'gold',
    position: [50, 85],
    opacity: 0.3,
    size: 20,
  },
];

const coolOption = [...sectionGradients, ...coolGradients];
const warmOption = [...sectionGradients, ...warmGradients];

const Content = (): JSX.Element => {
  const [gradients, setGradients] = useState<Gradient[]>(coolOption);

  // scroll depth tracking hook for segment analytics
  useScrollTracking();

  const handleTabChange = (index: number) => {
    switch (index) {
      case 1:
        setGradients(warmOption);
        break;
      case 2:
        setGradients(warmOption);
        break;
      case 3:
        setGradients(warmOption);
        break;
      case 4:
        setGradients(warmOption);
        break;
      default:
        setGradients(coolOption);
    }
  };

  return (
    <>
      <HomeHeroGate />
      <RingAssessmentModal />
      <RadialGradientBG background="sandstone-200" gradients={gradients}>
        <HomeRingModules />
        <HomeMembership />
        <HomeFeatures onTabChange={handleTabChange} />
      </RadialGradientBG>
      <InverseHeader enter exit>
        <HomePress />
      </InverseHeader>
    </>
  );
};

export default Content;
