import { ButtonV3, TypographyV3 } from '@jouzen/ecom-components';
import type { NumberFormatOptions } from 'next-intl';
import { useFormatter, useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import ArtDirectionImage from '@/app/components/Image/ArtDirectionImage';
import Motion from '@/app/components/Motion';
import { RingStyleEnum } from '@/app/enums/RingStyleEnum';
import useCurrency from '@/app/hooks/useCurrency';
import type { RingPricingData } from '@/queries/ProductPricing';
import { useRingsLowestPricing } from '@/queries/ProductPricing';

import ringCardData, { type RingCardData } from './_data/ringCardData';

interface BasePriceProps {
  readonly id: RingCardData['id'];
}

const BasePrice = ({ id }: BasePriceProps): JSX.Element => {
  const currency = useCurrency();
  const t = useTranslations();
  const format = useFormatter();
  const { data: lowestPricingData } = useRingsLowestPricing();

  if (lowestPricingData) {
    let lowestPrice: RingPricingData['lowestPrice'];
    if (id === 'gen3') {
      const heritageLowestPrice =
        lowestPricingData[RingStyleEnum.Heritage].lowestPrice;
      const horizonLowestPrice =
        lowestPricingData[RingStyleEnum.Horizon].lowestPrice;
      lowestPrice =
        heritageLowestPrice < horizonLowestPrice
          ? heritageLowestPrice
          : horizonLowestPrice;
    } else {
      lowestPrice = lowestPricingData[RingStyleEnum.OuraRing4].lowestPrice;
    }

    const formatterOptions: NumberFormatOptions = {
      currency: currency.currencyCode,
      style: 'currency',
      trailingZeroDisplay: 'stripIfInteger',
    };
    const formattedPrice = format.number(lowestPrice, formatterOptions);

    return (
      <TypographyV3
        className="text-sm md:text-white lg:text-base"
        weight="bold"
      >
        {t.rich('pdp_from', { amount: formattedPrice })}
      </TypographyV3>
    );
  }

  return (
    <TypographyV3 className="text-sm md:text-white lg:text-base" weight="bold">
      &nbsp;
    </TypographyV3>
  );
};

const HomeRingModules = () => {
  const t = useTranslations();

  const handleClick = async ({
    cta,
    location,
    path,
    action,
  }: {
    cta: string;
    location: string;
    path: string;
    action: string;
  }): Promise<void> => {
    await window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: cta,
      location: location,
      path: path,
      action: action,
    });
  };

  return (
    <section
      className="relative gap-6 px-5 pt-6 md:flex md:flex-row md:py-6"
      data-cy="home-ring-module"
    >
      {ringCardData.map(
        ({
          dataCyExplore,
          dataCyShop,
          hrefExplore,
          hrefShop,
          id,
          location,
          mobileSrc,
          pdpAction,
          popAction,
          src,
          title,
        }) => (
          <Motion
            className="flex-1"
            initial={{ opacity: 0 }}
            key={id}
            transition={{ duration: 1, type: 'tween', delay: 0.25 }}
            whileInView={{ opacity: 1 }}
          >
            <div
              className="relative size-full items-end pb-6 md:flex md:pb-0"
              data-cy={title}
            >
              <div className="relative aspect-[2048/1437] w-full overflow-hidden rounded-xl">
                <ArtDirectionImage
                  fill
                  sizes="95vw, (min-width: 768px) 46vw"
                  srcSet={[
                    {
                      media: '(max-width: 767px)',
                      src: mobileSrc,
                    },
                    {
                      media: '(min-width: 768px)',
                      src,
                    },
                  ]}
                />
              </div>
              <div className="flex w-full justify-between pt-4 md:absolute md:px-4 md:pb-4 md:pt-0 lg:px-6 lg:pb-6 xl:px-10 xl:pb-10">
                <div className="md:self-end">
                  <TypographyV3 className="text-2xl md:text-2xl md:font-extralight md:text-white lg:text-3xl xl:pb-[3px] xl:text-4xl xxl:text-5xl">
                    {t(title)}
                  </TypographyV3>
                  <div className="flex text-sandstone-500 md:text-white">
                    <BasePrice id={id} />
                  </div>
                </div>
                <div className="flex flex-row items-center md:flex-col-reverse md:items-end md:gap-2 lg:gap-4 xl:flex-row xl:items-end">
                  <ButtonV3
                    variant={'outlined-light'}
                    size="medium"
                    className="hidden w-full justify-around text-nowrap font-bold hover:text-gray-400 md:flex lg:px-4 lg:py-2 xl:px-6 xl:py-3"
                    href={hrefExplore}
                    data-cy={dataCyExplore}
                    onClick={() =>
                      handleClick({
                        cta: 'explore',
                        location,
                        path: hrefExplore,
                        action: popAction,
                      })
                    }
                  >
                    {t('explore')}
                  </ButtonV3>

                  <ButtonV3
                    variant="unset"
                    size="medium"
                    className="w-full justify-around text-nowrap bg-sandstone-500 font-bold text-sandstone-200 hover:bg-gray-600 md:border md:border-sandstone-100 md:bg-sandstone-200 md:text-sandstone-500 hover:md:border-gray-400 hover:md:bg-gray-400 lg:px-4 lg:py-2 xl:px-6 xl:py-3"
                    href={hrefShop}
                    data-cy={dataCyShop}
                    onClick={() =>
                      handleClick({
                        cta: 'shop',
                        location,
                        path: hrefShop,
                        action: pdpAction,
                      })
                    }
                  >
                    {t('shop')}
                  </ButtonV3>
                </div>
              </div>
            </div>
          </Motion>
        ),
      )}
    </section>
  );
};
export default HomeRingModules;
