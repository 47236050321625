import { useReducedMotion } from 'framer-motion';
import { memo } from 'react';

import Image from '@/app/components/Image';
import ArtDirectionImage from '@/app/components/Image/ArtDirectionImage';
import Motion from '@/app/components/Motion';
import Video from '@/app/components/Video/Video';

const StaticHero = () => {
  return (
    <Motion
      className="relative z-10 h-[calc(100svh-50px)] md:h-full"
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 1.1 }}
      transition={{ duration: 1.5, type: 'tween' }}
    >
      <ArtDirectionImage
        className="object-cover"
        fill
        localizedAlt="or4_home_page_hero_alt"
        priority
        sizes="100vw"
        srcSet={[
          {
            media: '(max-width: 767px)',
            src: '/blue-sky/home-hero/or4-mobile-alt-1.png',
          },
          {
            media: '(min-width: 768px)',
            src: 'Group_1321316957_L8SZ16.png',
          },
        ]}
      />
      <Image
        className="object-cover"
        fill
        format="png"
        localizedAlt="or4_home_page_hero_alt"
        priority
        sizes="100vw"
        src="/blue-sky/home-hero/or4-mobile-alt-1"
      />
    </Motion>
  );
};

const VideoHero = memo(function VideoHero() {
  return (
    <>
      <Video
        className="relative hidden h-screen min-h-[50em] w-full object-cover md:block"
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        posterSrc="or4-home-hero-desktop-fallback.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        src="gen4-hero-desktop-tony-2.mp4"
      />
      <Video
        className="relative size-full object-cover md:hidden"
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        posterSrc="or4-home-hero-mobile-fallback.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        src="gen4-hero-mobile-final.mp4"
      />
    </>
  );
});

const OR4HeroBackground = (): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();

  if (!shouldReduceMotion) {
    return <VideoHero />;
  } else {
    return <StaticHero />;
  }
};

export default OR4HeroBackground;
