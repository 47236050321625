import { cx } from 'class-variance-authority';
import { usePathname } from 'next/navigation';
import type { FocusEvent } from 'react';
import { useConfig } from 'statsig-react';
import type { MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';
import { type ImageFormat } from '@/app/components/Image';
import LandingPageHeroChiclet from '@/app/components/LandingPage/LandingPageHero/LandingPageHeroChiclet';

interface RenderData {
  additionalSegmentProperties: {
    cta: string;
    location: string;
    action: string;
  };
  ctaLocaleKey: MessageKey;
  image: {
    altKey: MessageKey;
    src: string;
    format: ImageFormat;
  };
  localeKey: MessageKey;
  target: string;
  rel: string;
  url: string;
}

interface HomeHeroShopChicletProps {
  readonly className?: string | undefined;
}

const HomeHeroShopChiclet = ({
  className,
}: HomeHeroShopChicletProps): JSX.Element | null => {
  const path = usePathname();
  const { config } = useConfig('home_hero_chiclet');
  const activeChicletConfig = Object.keys(config.value).length > 0;
  const value = config.value as unknown as RenderData;
  const renderData = activeChicletConfig && value;

  if (!renderData) return null;

  const handleFocus = (event: FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleClick = () => {
    const { action, cta, location } = renderData.additionalSegmentProperties;
    void window.ouraAnalytics.track(EventType.LinkClicked, {
      cta,
      action,
      location,
      path,
    });
  };

  return (
    <div
      className="mt-6 flex justify-center px-5 md:absolute md:bottom-8 md:right-6 md:z-30 md:mt-0 lg:bottom-14 lg:right-14"
      data-cy="home-hero-chiclet-wrapper"
    >
      <LandingPageHeroChiclet
        className={cx(className, '!mb-0')}
        cta={{
          href: renderData.url,
          label: renderData.ctaLocaleKey,
          rel: renderData.rel,
          target: renderData.target,
        }}
        data-cy="home-hero-shop-chiclet"
        image={{
          format: renderData.image.format,
          localizedAlt: renderData.image.altKey,
          src: renderData.image.src,
        }}
        motionProps={{
          transition: { delay: 1, duration: 0.25 },
        }}
        onClick={handleClick}
        onFocusWithin={handleFocus}
        title={renderData.localeKey}
      />
    </div>
  );
};

export default HomeHeroShopChiclet;
