import { ButtonV3, CloseXIcon, TypographyV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import { useRef } from 'react';
import { useDialog } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import { EventType } from '@/analytics/types';
import Image from '@/app/components/Image';

const QUIZ_PATH = '/ring-assessment';

const RingAssessmentModalContent = ({
  close, // eslint-disable-line @typescript-eslint/unbound-method
}: OverlayTriggerState): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const t = useTranslations();
  const { dialogProps, titleProps } = useDialog({}, wrapperRef);

  const handleStartQuizClicked = () => {
    void window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'Start User Quiz',
      location: 'quiz modal popup',
      action: 'start quiz',
      path: QUIZ_PATH,
    });
  };

  return (
    <div
      {...dialogProps}
      className="relative w-full shrink-0 overflow-hidden rounded-[10px] bg-sandstone-100 lg:flex lg:w-[776px] maxlg:max-w-[600px]"
      data-cy="ring-assessment-modal-dialog"
      ref={wrapperRef}
    >
      <button
        className="absolute right-2 top-5 flex size-11 items-center justify-center text-sandstone-500 lg:top-3"
        data-cy="ring-assessment-modal-close"
        onClick={close}
        type="button"
      >
        <CloseXIcon className="size-8" />
        <span className="sr-only">{t('close')}</span>
      </button>
      <div className="lg:w-1/2 lg:shrink-0 maxlg:hidden">
        <Image
          alt=""
          height={483}
          src="blue-sky/d2c-quiz/d2c-quiz-modal-feature.jpg?ar=4:5&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.46&fp-z=1"
          width={388}
        />
      </div>
      <div className="px-10 pb-12 pt-16 lg:w-1/2 lg:shrink-0 lg:py-14">
        <h2 className="mb-12 maxlg:text-center" {...titleProps}>
          <TypographyV3 className="text-4xl text-sandstone-500" Element="span">
            {t.rich('d2c_cta_modal_copy')}
          </TypographyV3>
        </h2>
        <ButtonV3
          className="w-full"
          href={QUIZ_PATH}
          onClick={handleStartQuizClicked}
          size="large"
          variant="outlined-dark"
        >
          {t('d2c_start')}
        </ButtonV3>
      </div>
    </div>
  );
};

export default RingAssessmentModalContent;
