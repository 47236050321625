import { useSwipeable } from '@jouzen/ecom-components';

import {
  LeftArrowBtn,
  RightArrowBtn,
} from '@/app/components/SwipeableNavButtons';

export interface OverlayButtonsProps {
  readonly onBack: () => void;
  readonly onForward: () => void;
}

export const OverlayButtons = ({
  onBack,
  onForward,
}: OverlayButtonsProps): JSX.Element => {
  const { canScrollNext, canScrollPrev } = useSwipeable();
  return (
    <div className="flex gap-x-3">
      <LeftArrowBtn color="gray" disabled={!canScrollPrev} onClick={onBack} />
      <RightArrowBtn
        color="gray"
        disabled={!canScrollNext}
        onClick={onForward}
      />
    </div>
  );
};
