import {
  ButtonV3,
  GridItem,
  InverseHeader,
  LayoutGrid,
  TypographyV3,
} from '@jouzen/ecom-components';
import type { RichTranslationValues } from 'next-intl';
import { useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import Motion from '@/app/components/Motion';

import { HomeFeaturesTabs } from './HomeFeaturesTabs';
import tValue from './HomeFeaturesTValue';
import HostingParty from './HostingParty';
import StartDay from './StartDay';
import TakeAWalk from './TakeAWalk';
import UnderWeather from './UnderWeather';
import WindDown from './WindDown';

interface HomeFeaturesProps {
  readonly onTabChange: (index: number) => void;
}

const HomeFeatures = ({ onTabChange }: HomeFeaturesProps): JSX.Element => {
  const t = useTranslations();

  const handleClick = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.LinkClicked, {
      cta: 'how it works',
      location: 'home features',
      path: '/how-it-works',
    });
  };

  return (
    <section className="relative pb-12 lg:pb-24" data-cy="home-features">
      <LayoutGrid>
        <GridItem
          className="lg:pb-32"
          colEnd={{ sm: 'main', lg: 14 }}
          colStart={{ sm: 'main', lg: 'main' }}
        >
          <TypographyV3
            weight="bold"
            Element="h2"
            variant="body-large"
            className="mb-6"
          >
            {t('features_eyebrow')}
          </TypographyV3>
          <Motion
            initial={{ filter: 'blur(5px)', opacity: 0 }}
            whileInView={{ filter: 'blur(0px)', opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <TypographyV3
              Element="h3"
              variant="h1"
              height="none"
              className="flex flex-col"
            >
              {t.rich('features_title', tValue as RichTranslationValues)}
            </TypographyV3>
          </Motion>
        </GridItem>
        <GridItem
          className="flex flex-col justify-end py-8 md:py-16 lg:py-32"
          colEnd={{ sm: 'main' }}
          colStart={{ sm: 'main', lg: 16 }}
        >
          <div>
            <TypographyV3>{t('features_copy')}</TypographyV3>
            <ButtonV3
              href="/how-it-works"
              variant="secondary-dark"
              className="mt-6"
              onClick={handleClick}
            >
              {t('how_it_works_link')}
            </ButtonV3>
          </div>
        </GridItem>
        <GridItem
          className="lg:hidden"
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'full' }}
        >
          <InverseHeader enter>
            <StartDay />
            <TakeAWalk />
            <UnderWeather />
            <WindDown />
            <HostingParty />
          </InverseHeader>
        </GridItem>
        <GridItem
          className="hidden lg:block lg:min-h-[825px] xl:min-h-[715px]"
          colEnd={{ lg: 23 }}
          colStart={{ lg: 3 }}
        >
          <InverseHeader enter>
            <Motion
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, type: 'tween', delay: 0.25 }}
            >
              <HomeFeaturesTabs onTabChange={onTabChange} />
            </Motion>
          </InverseHeader>
        </GridItem>
      </LayoutGrid>
    </section>
  );
};

export default HomeFeatures;
