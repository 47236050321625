import type { ReactNode } from 'react';

import Motion from '@/app/components/Motion';

const tValue = {
  em: function em(chunks: ReactNode) {
    return (
      <Motion
        className="self-end"
        el="span"
        initial={{ filter: 'blur(5px)', opacity: 0 }}
        whileInView={{ filter: 'blur(0px)', opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.6 }}
      >
        <em className="font-serif italic">{chunks}</em>
      </Motion>
    );
  },
};

export default tValue;
