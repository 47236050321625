import isBrowser from '@/utils/isBrowser';

/**
 * Write to browser session storage
 * @param key string
 * @param value string
 */
export const writeToSessionStorage = (key: string, value: string): void => {
  if (isBrowser()) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (error) {
      // Do nothing on error
    }
  }
};

/**
 * Read from browser session storage
 * @param key string
 */
export const readFromSessionStorage = (key: string): string | null => {
  if (isBrowser()) {
    try {
      return window.sessionStorage.getItem(key);
    } catch (error) {
      // Do nothing on error
    }
  }
  return null;
};

/**
 * Remove item from browser session storage
 * @param key string
 */
export const removeFromSessionStorage = (key: string): void => {
  if (isBrowser()) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      // Do nothing on error
    }
  }
};
