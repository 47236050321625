import type { ArticleImageProps } from '@/app/components/ArticleCardsModule';
import type { ArticleCardDataProps } from '@/app/components/ArticleCardsModule/ArticleCard';

export const ARTICLE_CARDS: ArticleCardDataProps[] = [
  {
    heading: 'homepage_press_article_1',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.wired.com/review/oura-ring-4/',
      rel: 'noopener noreferrer',
      siteName: 'Wired',
      target: '_blank',
    },
  },
  {
    heading: 'homepage_press_article_2',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.digitaltrends.com/mobile/oura-ring-4-is-here-and-it-looks-superb/',
      rel: 'noopener noreferrer',
      siteName: 'digitaltrends',
      target: '_blank',
    },
  },
  {
    heading: 'homepage_press_article_3',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.tomsguide.com/wellness/fitness-trackers/i-went-hands-on-with-the-new-oura-ring-4-big-redesign-8-days-of-battery-life-and-all-new-app',
      rel: 'noopener noreferrer',
      siteName: "tom's guide",
      target: '_blank',
    },
  },
];

export const ARTICLE_IMAGE: ArticleImageProps = {
  alt: 'press_section_primary_image_alt',
  buttonProps: {
    external: true,
    href: 'https://www.cnet.com/tech/mobile/oura-ring-4-first-take-more-titanium-better-accuracy-and-longer-battery-life/',
    label: 'read_more',
    rel: 'noopener noreferrer',
    siteName: 'CNET',
    target: '_blank',
    variant: 'secondary-light',
  },
  heading: 'homepage_press_hero_article',
  src: 'homepage/press-hero',
  headingClassname: 'max-w-[590px]',
};
