import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

type HomeHeroWithChicletWrapperProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'>;

const HomeHeroWithChicletWrapper = ({
  children,
  ...props
}: HomeHeroWithChicletWrapperProps): JSX.Element => (
  <div className="relative bg-sandstone-200" {...props}>
    {children}
  </div>
);

export default HomeHeroWithChicletWrapper;
